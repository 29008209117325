.ContactMain {
    padding-top: 10%;
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ContactForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5% 0% 5% 5%;
    width: 100%;
    max-width: 400px;
    background-color: rgb(255, 255, 0);
    border: 2px solid black;
    border-radius: 5px;
    box-shadow: 0px 10px 13px rgb(34, 34, 34);
}

.f {
    padding-top: 3%;
} 

.SubmitButton {
    background-color: white;
    border: black 2 px solid;
    border-radius: 5px;
    margin-top: 3%;
}